/**
 * Importing the action types.
 */
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_CHECK,
  LOGOUT_USER
} from "./loginTypes";

/**
 * Defining the endpoints that will be utilized in the fetch.
 */
const loginEndpoint: string = "/login";
const homeEndpoint: string = "/home";
const logoutEndpoint: string = "/login/destroy";

/**
 * Defining the loginRequest function, that handles the requests and set
 * a loading phase in the React Component.
 */
export function loginRequest(newRequest: boolean) {
  return {
    type: LOGIN_REQUEST,
    payload: newRequest
  };
}

/**
 * Defining the loginSuccess function, that handles the token and log the
 * user in the dashboardComponent.
 */
export function loginSuccess(sessionToken: string) {
  return {
    type: LOGIN_SUCCESS,
    payload: sessionToken
  };
}

/**
 * Defining the loginFailure function that handles the errors.
 */
export function loginFailure(errorFound: boolean) {
  return {
    type: LOGIN_FAILURE,
    payload: errorFound
  };
}

/**
 * Defining loginCheck, a function that checks if a user token exists
 * or not.
 */
export function loginCheck(sessionToken: string) {
  return {
    type: LOGIN_CHECK,
    payload: sessionToken
  };
}

/**
 * Defining the logoutUser action.
 */
export function logoutUser(isDone: string) {
  return {
    type: LOGOUT_USER,
    payload: isDone
  };
}

/**
 * generateToken fetches from the API served in the Express Server the token.
 */
async function generateToken(userData: object) {
  try {
    const response = await fetch(loginEndpoint, {
      method: "POST",
      body: JSON.stringify(userData),
      headers: { "Content-Type": "application/json" }
    });

    const json = response.text();
    return json;
  } 
  
  catch (error) {
    throw error;
  }
}

/**
 * This function checks if there is a token available or not.
 */
async function getCheck() {
  try {
    const response = await fetch(homeEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" }
    });

    const json = await response.text();
    return json;
  } 
  
  catch (error) {
    throw error;
  }
}

/**
 * This function generates a logout request, that allows the user
 * to logout from the dashboard destroying is token.
 */
async function logoutRequest() {
  try {
    const response = await fetch(logoutEndpoint, {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    });

    const json = response.text();
    return json;
  }

  catch (error) {
    throw error;
  }
}

/**
 * This function logs the user in the dashboardComponent.
 */
export function loginUser(codiceUtente: string, passwordUtente: string) {
  return async (dispatch: any) => {
    dispatch(loginRequest(true));

    const userData = {
      usercode: codiceUtente,
      password: passwordUtente
    };

    await generateToken(userData)
      .then((data: string) => {
        dispatch(loginSuccess(data));
      })

      .catch((error: any) => {
        console.log(error);
        dispatch(loginFailure(true));
      });
  };
}

/**
 * Describing how checkLogin should be doing his work.
 * checkLogin is a function that takes back the session token
 * (if there is one).
 */
export function checkLogin() {
  return async (dispatch: any) => {
    await getCheck()
      .then((data: string) => {
        data[0] !== "*"
          ? dispatch(loginFailure(true))
          : dispatch(loginCheck(data));
      })

      .catch(error => {
        console.log(error);
        dispatch(loginFailure(true));
      });
  };
}

/**
 * Logout user action that destroys session and redirects user to login page.
 */
export function logoutUserRequest() {
  return async (dispatch: any) => {
    dispatch(loginRequest(true));

    await logoutRequest()
      .then((data: string) => {
        dispatch(logoutUser(""));
      })

      .catch(error => {
        console.log(error);
        dispatch(loginFailure(true));
      });
  };
}
