/**
 * Importing modules from react, react-redux and react-spinners.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { HashLoader } from "react-spinners";
import posed from "react-pose";
import DatePicker from "react-datepicker";
import _ from "lodash";

import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
} from "reactstrap";

/**
 * Importing actions, AppState and RenderNumbers component.
 */
import { loadNumbers } from "../store/numbers/numbersListActions";
import { loadDetail } from "../store/details/detailActions";
import { logoutUserRequest, checkLogin } from "../store/login/loginActions";
import { AppState } from "../store/store";
import RenderNumbers from "./renderNumbersComponent";

/**
 * Importing bootstrap, font-awesome and dashboardComponent.css.
 */
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import "../styles/dashboardComponent.css";
import "react-datepicker/dist/react-datepicker.css";

/**
 * pricesTotal is a scoped variable that will contain all the consumptions
 * of the numbers.
 *
 * internationalTotal will contain the total consumptions for
 * internationals calls.
 *
 * groupedCalls will have all the types of calls that the user has done.
 */
export let pricesTotal: number[] = [];
export let internationalTotal: number[] = [];
export let notGeographicalTotal: number[] = [];
let groupedCalls: any = [];

/**
 * Describing the AppProps interface.
 */
interface AppProps {
  numbers: any;
  detail: any;
  login: any;
  history: any;
  detailButtonState: boolean;
  loadNumbers: Function;
  loadDetail: Function;
  logoutUserRequest: Function;
  checkLogin: Function;
}

/**
 * Describing IState interface
 */
interface IState {
  passCheck: number;
  loadedNumbers: number;
  isVisible: boolean;
  selectedDate: any;
  disableDatePicker: boolean;
  disableSendButton: boolean;
  disableInfoButton: boolean;
  disableButton: boolean;
  disableLogout: boolean;
  popoverOpen: boolean;
}

const Box = posed.div({
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
});

/**
 * Describing Dashboard component
 */
class Dashboard extends Component<AppProps, IState> {
  /**
   * Defining the Dashboard constructor.
   */
  constructor(props: AppProps) {
    super(props);

    this.state = {
      passCheck: 0,
      loadedNumbers: 0,
      isVisible: false,
      selectedDate: null,
      disableDatePicker: true,
      disableSendButton: true,
      disableButton: true,
      disableInfoButton: true,
      disableLogout: true,
      popoverOpen: false,
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onDateSend = this.onDateSend.bind(this);
    this.togglePopover = this.togglePopover.bind(this);
  }

  /**
   * After component mounts it will do a function call to loadNumbers().
   */
  async componentDidMount() {
    // If we have already the numbers, we don't need to request anymore to the
    // server.
    if (this.props.numbers.data.Response !== undefined) {
      // Timeout for the animation.
      setTimeout(() => {
        this.setState({
          isVisible: !this.state.isVisible,
        });
      }, 500);

      await this.setState({
        passCheck: 1,
        disableButton: false,
        disableDatePicker: false,
        disableSendButton: false,
        disableInfoButton: false,
        disableLogout: false,
      });
    } else {
      // Asking to Clouditalia's Servers our numbers.
      await this.props.loadNumbers();

      // Timeout for the animation.
      setTimeout(() => {
        this.setState({
          isVisible: !this.state.isVisible,
        });
      }, 500);

      // Asking for the call registry for every number.
      if (this.state.selectedDate) {
        await this.props.loadDetail(
          this.getNumbersList(),
          this.state.selectedDate
        );

        // Calculating number consumptions.
        await this.getNumberConsumptions();
      }


      await this.setState({
        passCheck: 1,
        disableButton: false,
        disableDatePicker: false,
        disableSendButton: false,
        disableInfoButton: false,
        disableLogout: false,
      });
    }
  }

  /**
   * This function returns a number list.
   * It iterates over the numbers.data, getting only the number.
   */
  getNumbersList() {
    let numbersList = [];
    let actualList = this.props.numbers.data.Response[0].NumberList;

    for (let index = 0; index < actualList.length; index++) {
      numbersList[index] = actualList[index].Numero;
    }

    return numbersList;
  }

  /**
   * This function iterates over the price of the single call and sums it up
   * for giving up the total consumpion for any given number.
   */
  getNumberConsumptions() {
    let detailData = this.props.detail.data;
    let numbersData = this.props.numbers.data.Response[0].NumberList;
    let detailsMap = new Map();

    groupedCalls = [];
    pricesTotal = [];
    internationalTotal = [];
    notGeographicalTotal = [];

    const numbersLength = detailData.length;

    /**
     * Saving in a map the total number of calls associated to their
     * number.
     */
    for (let index = 0; index < numbersLength; index++) {
      detailsMap.set(
        detailData[index].Numero,
        detailData[index].Calldetails.length
      );
    }

    for (let indexA = 0; indexA < numbersLength; indexA++) {
      for (let indexB = 0; indexB < numbersLength; indexB++) {
        if (numbersData[indexA].Numero === detailData[indexB].Numero) {
          groupedCalls.push(
            _.groupBy(detailData[indexB].Calldetails, function (call: any) {
              return call.Type;
            })
          );

          numbersData[indexA].price = 0;

          for (
            let indexC = 0;
            indexC < detailsMap.get(detailData[indexB].Numero);
            indexC++
          ) {
            numbersData[indexA].price += parseFloat(
              detailData[indexB].Calldetails[indexC].Price
            );
          }

          numbersData[indexA].price = numbersData[indexA].price.toFixed(2);
          pricesTotal.push(numbersData[indexA].price);
        }
      }

      if (groupedCalls[indexA].hasOwnProperty("internazionali")) {
        let total: any = 0;

        for (
          let i = 0;
          i < groupedCalls[indexA]["internazionali"].length;
          i++
        ) {
          total += parseFloat(
            groupedCalls[indexA]["internazionali"][i]["Price"]
          );
        }

        total = total.toFixed(2);
        internationalTotal[indexA] = total;
      } else {
        internationalTotal[indexA] = 0;
      }

      if (groupedCalls[indexA].hasOwnProperty("non geografiche")) {
        let total: any = 0;

        for (
          let i = 0;
          i < groupedCalls[indexA]["non geografiche"].length;
          i++
        ) {
          total += parseFloat(
            groupedCalls[indexA]["non geografiche"][i]["Price"]
          );
        }

        total = total.toFixed(2);
        notGeographicalTotal[indexA] = total;
      } else {
        notGeographicalTotal[indexA] = 0;
      }
    }
  }

  /**
   * This function destroys session's token and redirects the user to the
   * login page.
   */
  async logoutUser() {
    await this.props.logoutUserRequest();

    setTimeout(() => {
      this.props.history.push("/");
    }, 500);
  }

  /**
   * This method redirects the user to the root or dashboard page, based on
   * what is its authorization.
   */
  redirectUser() {
    setTimeout(() => {
      this.props.history.push("/");
    }, 1500);

    return;
  }

  /**
   * This method will change state to the selectedDate state variable.
   */
  onDateChange(date: Date) {
    this.setState({
      selectedDate: date,
    });
  }

  /**
   * This function listens if the user wants to check consumptions to
   * another date.
   */
  async onDateSend() {
    let dateToCompare: any = new Date();
    dateToCompare = dateToCompare.toJSON();
    dateToCompare = dateToCompare.split("T")[0];

    let currentDate: string = this.state.selectedDate.toJSON();
    currentDate = currentDate.split("T")[0];

    if (dateToCompare === currentDate) {
      return 0;
    } else {
      this.setState({
        disableButton: true,
        disableDatePicker: true,
        disableSendButton: true,
        disableInfoButton: true,
        disableLogout: true,
      });

      // Asking for the call registry for every number.
      await this.props.loadDetail(
        this.getNumbersList(),
        this.state.selectedDate
      );

      // Calculating number consumptions.
      await this.getNumberConsumptions();

      this.setState({
        disableButton: false,
        disableDatePicker: false,
        disableSendButton: false,
        disableInfoButton: false,
        disableLogout: false,
      });
    }
  }

  togglePopover() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  /**
   * Dashboard's render method.
   */
  render() {
    if (this.props.numbers.loading === false) {
      if (this.props.numbers.error !== true) {
        return (
          <div className="container dashboardComponentMain p-5">
            <Box pose={this.state.isVisible ? "visible" : "hidden"}>
              <div className="row pb-1 ">
                <div>
                  <h4 className="pt-3 pr-3">Benvenuto</h4>
                </div>

                <div>
                  <button
                    type="button"
                    className="btn btn-success mr-2 mt-3 mb-3"
                    onClick={this.logoutUser.bind(this)}
                    disabled={this.state.disableLogout}
                  >
                    <i className="fa fa-sign-out" />
                  </button>
                </div>

                <div>
                  <button
                    type="button"
                    className="btn btn-success mr-3 mt-3 mb-3"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Tooltip on top"
                    onClick={() => window.location.reload()}
                  >
                    <i className="fa fa-refresh" />
                  </button>
                </div>
              </div>

              <div className="alert alert-danger row mr-1" role="alert">
                Attenzione: Prima dei prossimi aggiornamenti, non sara'
                possibile effettuare molteplici refresh di pagina.
              </div>

              <div className="row">
                <label className="">
                  Seleziona una data se vuoi consultare i consumi di un
                  determinato mese ad uno specifico giorno.
                </label>
              </div>

              <div className="row mb-3">
                <DatePicker
                  className="form-control dashboardComponentDatePicker"
                  selected={this.state.selectedDate}
                  onChange={this.onDateChange}
                  maxDate={new Date()}
                  disabled={this.state.disableDatePicker}
                />

                <button
                  className="btn btn-success ml-2"
                  disabled={this.state.disableSendButton}
                  onClick={this.onDateSend}
                >
                  <span className="fa fa-send" />
                </button>

                <Button
                  id="info-popover"
                  className="ml-2 btn btn-success"
                  type="button"
                  disabled={this.state.disableInfoButton}
                >
                  <span className="fa fa-info-circle" />
                </Button>

                <UncontrolledPopover
                  placement="right"
                  trigger="legacy"
                  isOpen={this.state.popoverOpen}
                  target="info-popover"
                  toggle={this.togglePopover}
                >
                  <PopoverHeader className="dashboardComponentPopoverHeader">
                    Come funziona?
                  </PopoverHeader>

                  <PopoverBody className="dashboardComponentPopoverBody">
                    Selezionando, ad esempio, il 01-20-2019, i dettagli che
                    saranno presentati, andranno dal 01-01-2019 al 01-20-2019.
                  </PopoverBody>
                </UncontrolledPopover>
              </div>

              <div className="dashboardComponentRenderNumbers row">
                <RenderNumbers
                  numbers={this.props.numbers.data.Response[0].NumberList}
                  detail={this.props.detail.data}
                  total={pricesTotal}
                  history={this.props.history}
                  detailButtonState={this.state.disableButton}
                  selectedDate={this.state.selectedDate}
                  internationalConsumption={internationalTotal}
                  notGeographicalConsumption={notGeographicalTotal}
                />
              </div>
            </Box>
          </div>
        );
      } else {
        return (
          <div className="dashboardComponentLoadingErrorScreen">
            An error has occurred, you will be redirected to the login page.
            {this.redirectUser()}
          </div>
        );
      }
    } else {
      return (
        <div className="dashboardComponentLoadingErrorScreen">
          <HashLoader color={"#28a745"} size={75} />
        </div>
      );
    }
  }
}

/**
 * Mapping redux store state to component props.
 */
const mapStateToProps = (state: AppState) => {
  return {
    numbers: state.numbers,
    detail: state.detail,
    login: state.login,
  };
};

/**
 * Exporting Dashboard and connecting it to the redux store.
 */
export default connect(mapStateToProps, {
  loadNumbers,
  loadDetail,
  logoutUserRequest,
  checkLogin,
})(Dashboard);
