/**
 * Importing data interface and action types for defining the loginReducer.
 */
import { 
  ILogin, 
  LOGIN_REQUEST, 
  LOGIN_SUCCESS, 
  LOGIN_FAILURE, 
  LOGIN_CHECK, 
  LOGOUT_USER,
  ILoginActionTypes 
} from './loginTypes';

/**
 * Defining the initalState for the login data.
 */
const initalState: ILogin = {
  data: '',
  loading: false,
  error: false
}

/**
 * Defining the loginReducer, a function that switches over the defined
 * actions: LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE.
 */
export function loginReducer(state = initalState, action: ILoginActionTypes): ILogin {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return { 
        ...state, 
        data: '', 
        loading: action.payload, 
        error: false 
      };
    }

    case LOGIN_SUCCESS: {
      return { 
        ...state, 
        data: action.payload, 
        loading: false, 
        error: false 
      };
    }

    case LOGIN_FAILURE: {
      return { 
        ...state, 
        data: '', 
        loading: false, 
        error: action.payload 
      };
    }

    case LOGIN_CHECK: {
      return { 
        ...state, 
        data: action.payload, 
        loading: false, 
        error: false 
      };
    }

    case LOGOUT_USER: {
      return {
        ...state, 
        data: action.payload,
        loading: false,
        error: false
      };
    }
    
    default: {
      return state;
    }
  }
}