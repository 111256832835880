/**
 * Importing modules from redux, redux-thunk and redux-logger.
 */
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

/**
 * Importing the action reducers.
 */
import { loginReducer } from './login/loginReducer';
import { numbersReducer } from './numbers/numbersListReducer';
import { detailReducer } from './details/detailReducer';

/**
 * Combining all the reducers all together.
 */
const rootReducer = combineReducers({
    login: loginReducer,
    numbers: numbersReducer,
    detail: detailReducer
});

/**
 * Exporting AppState, that will be used in mapStateToProps.
 */
export type AppState = ReturnType<typeof rootReducer>;

/**
 * Defining the function for configuring the redux store.
 */
export const configureStore = () => {
    const store = createStore(
        rootReducer,
        applyMiddleware(thunk, logger)
    );

    return store;
}