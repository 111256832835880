/**
 * Setting up the interface for login data. 
 */
export interface IDetail {
  data: {},
  loading: boolean,
  error: boolean
}

/**
 * Describing the actions involved in getting the call details. 
 */
export const DETAIL_REQUEST = "DETAIL_REQUEST";
export const DETAIL_SUCCESS = "DETAIL_SUCCESS";
export const DETAIL_FAILURE = "DETAIL_FAILURE";

/**
 * Describing the interface of those actions. 
 */
export interface IDetailRequestAction {
  type: typeof DETAIL_REQUEST,
  payload: boolean
}

export interface IDetailSuccessAction {
  type: typeof DETAIL_SUCCESS,
  payload: object
}

export interface IDetailFailureAction {
  type: typeof DETAIL_FAILURE,
  payload: boolean
}

/**
 * Exporting all the interfaces in one. 
 */
export type IDetailActionTypes = IDetailRequestAction | IDetailSuccessAction | IDetailFailureAction;