/**
 * Setting up the interface for login data. 
 */
export interface ILogin {
	data: string,
	loading: boolean,
	error: boolean
}

/**
 * Describing the actions involved in login. 
 */
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_CHECK = "LOGIN_CHECK";
export const LOGOUT_USER = "LOGOUT_USER";

/**
 * Describing the interface of those actions. 
 */
interface ILoginRequestAction {
	type: typeof LOGIN_REQUEST,
	payload: boolean
}

interface ILoginSuccessAction {
	type: typeof LOGIN_SUCCESS,
	payload: string
}

interface ILoginFailureAction {
	type: typeof LOGIN_FAILURE,
	payload: boolean
}

interface ILoginCheckAction {
	type: typeof LOGIN_CHECK,
	payload: string
}

interface ILogoutUserAction {
	type: typeof LOGOUT_USER,
	payload: string
}

/** 
 * Exporting all the interfaces in one. 
 */
export type ILoginActionTypes = ILoginRequestAction | ILoginSuccessAction | ILoginFailureAction | ILoginCheckAction | ILogoutUserAction