/**
 * Setting up the interface for taking the numbers list. 
 */
export interface INumbers {
	data: object,
	loading: boolean,
	error: boolean
}

/**
 * Describing the actions involved in getting the numbers. 
 */
export const NUMBER_REQUEST = "NUMBER_REQUEST";
export const NUMBER_SUCCESS = "NUMBER_SUCCESS";
export const NUMBER_FAILURE = "NUMBER_FAILURE";

/**
 * Describing the interface of those actions. 
 */
export interface INumbersRequestAction {
	type: typeof NUMBER_REQUEST,
	payload: boolean
}

export interface INumbersSuccessAction {
	type: typeof NUMBER_SUCCESS,
	payload: object
}

export interface INumbersFailureAction {
	type: typeof NUMBER_FAILURE,
	payload: boolean
}

/** 
 * Exporting all the interfaces in one. 
 */
export type INumbersActionTypes = INumbersRequestAction | INumbersSuccessAction | INumbersFailureAction;