/**
 * Importing INumbers interface and numbers action types.
 */
import { 
  INumbers, 
  NUMBER_REQUEST, 
  NUMBER_SUCCESS, 
  NUMBER_FAILURE, 
  INumbersActionTypes 
} from './numbersListTypes';

/**
 * Setting up the inital state for numbersReducer state.
 */
const initialState: INumbers = { 
  data: {},
  loading: true,
  error: false
}

/**
 * Defining numbersReducer function that switches over the defined
 * actions for the numbers.
 */
export function numbersReducer(state = initialState, action: INumbersActionTypes): INumbers {
  switch (action.type) {
    case NUMBER_REQUEST: {
      return {
        ...state, 
        data: {}, 
        loading: action.payload, 
        error: false
      };
    }

    case NUMBER_SUCCESS: {
      return {
        ...state, 
        data: action.payload, 
        loading: false, 
        error: false
      };
    }

    case NUMBER_FAILURE: {
      return {
        ...state, 
        data: {}, 
        loading: false, 
        error: action.payload
      };
    }
    
    default: {
      return state;
    }
  }
}