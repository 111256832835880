/**
 * Importing modules from react.
 */
import React, { Component } from 'react';
import '../styles/renderNumbersComponent.css';
import { ClipLoader } from 'react-spinners';

/**
 * Setting up the RenderNumbersProps interface
 */
interface RenderNumbersProps {
    numbers: object[],
    total: number[],
    detail: any,
    history: any,
    detailButtonState: boolean,
    selectedDate: Date,
    internationalConsumption: number[],
    notGeographicalConsumption: number[]
}

/**
 * Defining the functional component RenderNumbers.
 * This component renders a table containing the numbers and all
 * the informations about them.
 */
class RenderNumbers extends Component<RenderNumbersProps> {
  // eslint-disable-next-line
  constructor(props: RenderNumbersProps) {
    super(props);
  }

  getDetailComponent(detailNumber: string, i: number, detail: any, total: number, ragioneSociale: string, selectedDate: Date) {
    this.props.history.push({
      pathname: '/detail',
      state: {
        number: detailNumber,
        index: i,
        detail: detail,
        total: total,
        ragioneSociale: ragioneSociale,
        selectedDate: selectedDate
      }
    });
  }

  render() {
    let totalSum = this.props.total.reduce((prevElem: any, elem: any) => parseFloat(prevElem) + parseFloat(elem), 0);
    let internationalTotalSum = this.props.internationalConsumption.reduce((prevElem: any, elem: any) => parseFloat(prevElem) + parseFloat(elem), 0);
    let notGeographicalTotalSum = this.props.notGeographicalConsumption.reduce((prevElem: any, elem: any) => parseFloat(prevElem) + parseFloat(elem), 0);
  
    return (
      <div>
          <div className="table-responsive mr-3 rounded">
          <table className="table table-striped table-dark table-sm">
            <thead className="thead-light">
              <tr>
                <th scope="col">Consumo Totale</th>
                <th scope="col">Internazionali Totale</th>
                <th scope="col">Non Geografiche Totale</th>
              </tr>
            </thead>
    
            <tbody>
              <tr>
                <td>{totalSum.toFixed(2)}&euro;</td>
                <td>{internationalTotalSum.toFixed(2)}&euro;</td>
                <td>{notGeographicalTotalSum.toFixed(2)}&euro;</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="table-responsive mr-3 rounded">
          <table className="table table-striped table-dark table-sm">
            <thead className="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Numero</th>
                <th scope="col">Portato</th>
                <th scope="col">Data Attivazione</th>
                <th scope="col">Ch. Contemporanee</th>
                <th scope="col">Rag. Sociale</th>
                <th scope="col">Consumo</th>
                <th scope="col">Internazionali</th>
                <th scope="col">Non Geografiche</th>
                <th scope="col">Dettaglio</th>
              </tr>
            </thead>
    
            <tbody>
              {this.props.numbers.map((number: any, i: number) => {
                return (
                  <tr key={number.Numero}>
                    <th scope="row">{i+1}</th>
                    <td>{number.Numero}</td>
                    <td>{number.Portato}</td>
                    <td>{number.DataAttivazione}</td>
                    <td>{number["Chiamate contemporanee"]}</td>   
                    <td>{number.RagioneSociale}</td>
                    {
                      (this.props.total[i])
                        ? <td>{this.props.total[i]}&euro;</td>
                        : <td><ClipLoader color={"#28a745"} size={25} /></td>
                    }
                    {
                      (this.props.internationalConsumption[i])
                        ? <td>{this.props.internationalConsumption[i]}&euro;</td>
                        : (this.props.internationalConsumption[i] === 0)
                          ? <td>0.00&euro;</td>
                          : <td><ClipLoader color={"#28a745"} size={25} /></td>
                    }
                    {
                      (this.props.notGeographicalConsumption[i])
                        ? <td>{this.props.notGeographicalConsumption[i]}&euro;</td>
                        : (this.props.notGeographicalConsumption[i] === 0)
                          ? <td>0.00&euro;</td>
                          : <td><ClipLoader color={"#28a745"} size={25} /></td>
                    }
                    <td>
                      <button 
                        type="button" 
                        className="btn btn-success"
                        onClick={() => this.getDetailComponent(number.Numero, i, this.props.detail[i], this.props.total[i], number.RagioneSociale, this.props.selectedDate)}
                        disabled={this.props.detailButtonState}
                      >
                        <i className="fa fa-book"></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default RenderNumbers;