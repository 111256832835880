/**
 * Importing modules from react and react-redux.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { HashLoader } from "react-spinners";
import posed from "react-pose";

/**
 * Importing actions, AppState, ILogin interface and scripts.
 */
import { loginUser, checkLogin } from "../store/login/loginActions";
import { AppState } from "../store/store";
import { ILogin } from "../store/login/loginTypes";
import sleep from "../scripts/sleep";

/**
 * Importing bootstrap, font-awesome and loginComponent.css.
 */
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import "../styles/loginComponent.css";

/**
 * Defining AppProps interface.
 */
interface AppProps {
  login: ILogin;
  loginUser: Function;
  checkLogin: Function;
  history: any;
}

/**
 * Defining IState interface, used for this.state.
 */
interface IState {
  codiceUtente: string;
  passwordUtente: string;
  proceed: number;
  isVisible: boolean;
}

/**
 * Defining the animation box.
 */
const Box = posed.div({
  hidden: 
    { opacity: 0 },
    
  visible: 
    { opacity: 1 },
});

/**
 * Defining Login Component.
 */
class Login extends Component<AppProps, IState> {
  // Setting a timeout variable.
  settingVisibility: any = null;

  /**
   * Login constructor with props defined in AppProps interface.
   * Here we initialize state and bind functions.
   */
  constructor(props: AppProps) {
    super(props);

    this.state = {
      codiceUtente: "",
      passwordUtente: "",
      proceed: 0,
      isVisible: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleChangeUser = this.handleChangeUser.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  /**
   * When the component did mount, it will check if there is a
   * token or not.
   */
  async componentDidMount() {
    // Checking if the user is already logged in.
    await this.props.checkLogin();

    // Simple timeout for displaying an animation.
    this.settingVisibility = setTimeout(() => {
      this.setState({
        isVisible: !this.state.isVisible
      });
    }, 1250);

    await sleep(750);

    // Setting the proceed state to 1, so that the user can go forward.
    await this.setState({
      proceed: 1
    });

    // If the user is logged in, we redirect him to dashboard.
    if (this.props.login.data[0] === "*") {
      this.props.history.push("/dashboard");
    }
  }

  /**
   * When component unmounts, it clears timeouts.
   */
  componentWillUnmount() {
    clearTimeout(this.settingVisibility);
  }

  /**
   * It checks for changes on user text box.
   */
  handleChangeUser(event: any) {
    this.setState({
      codiceUtente: event.target.value
    });
  }

  /**
   * It checks for changes on password text box.
   */
  handleChangePassword(event: any) {
    this.setState({
      passwordUtente: event.target.value
    });
  }

  /**
   * Defining the function for posting user and password.
   */
  async handleClick(event: any) {
    // It prevents a send button error.
    event.preventDefault();

    await this.props.loginUser(
      this.state.codiceUtente,
      this.state.passwordUtente
    );

    this.props.history.push("dashboard");
  }

  /**
   * Login's render method.
   */
  render() {
    if (this.state.proceed) {
      return (
        <div className="loginComponentMain bg-dark">
          <Box pose={this.state.isVisible ? "visible" : "hidden"}>
            <span style={{ color: "#28a745" }} className="">
              <i className="fa fa-user fa-3x" />
            </span>

            <form className="container">
              <label
                htmlFor="inputCodiceUtente"
                className="col-5 col-sm-4 col-md-3 col-lg-2 col-xl-2 mt-3 loginComponentLabel"
              >
                Codice Utente
              </label>

              <div className="form-group row justify-content-center">
                <input
                  type="text"
                  value={this.state.codiceUtente}
                  className="form-control col-5 col-sm-4 col-md-3 col-lg-2 col-xl-2 ml-2 mr-2 inputFontSize"
                  id="inputCodiceUtente"
                  placeholder="Codice Utente"
                  onChange={this.handleChangeUser}
                  required
                />
              </div>

              <label
                htmlFor="inputPasswordUtente"
                className="col-5 col-sm-4 col-md-3 col-lg-2 col-xl-2 loginComponentLabel"
              >
                Password Utente
              </label>

              <div className="form-group row justify-content-center">
                <input
                  type="password"
                  value={this.state.passwordUtente}
                  className="form-control col-5 col-sm-4 col-md-3 col-lg-2 col-xl-2 ml-2 mr-2 inputFontSize"
                  id="inputPasswordUtente"
                  placeholder="Password"
                  onChange={this.handleChangePassword}
                  required
                />
              </div>

              <div className="form-group row justify-content-center">
                <button
                  type="submit"
                  className="btn btn-success mt-2 ml-2 mr-2"
                  onClick={this.handleClick}
                  disabled={
                    !this.state.codiceUtente || !this.state.passwordUtente
                  }
                >
                  Login
                </button>
              </div>
            </form>
          </Box>
        </div>
      );
    } 
    
    else {
      return (
        <div className="loginComponentLoadingError bg-dark">
          <HashLoader color={"#28a745"} size={75} />
        </div>
      );
    }
  }
}

/**
 * Mapping redux store state to component props.
 */
const mapStateToProps = (state: AppState) => ({
  login: state.login
});

/**
 * Exporting Login and connecting it to redux store.
 */
export default connect(
  mapStateToProps,
  { loginUser, checkLogin }
)(Login);
