/**
 * Importing numbers action types.
 */
import {
  NUMBER_REQUEST,
  NUMBER_SUCCESS,
  NUMBER_FAILURE
} from "./numbersListTypes";

/**
 * Defining the endpoint that will be used in the fetch.
 */
const dashboardEndpoint: string = "/dashboard";

/**
 * Defining numbersRequest, a function that indicates to the component
 * that was istantiated a request action.
 */
export function numbersRequest(newRequest: boolean) {
  return {
    type: NUMBER_REQUEST,
    payload: newRequest
  };
}

/**
 * Defining numbersSuccess, a function that gives back the numbersList.
 */
export function numbersSuccess(numbersList: object) {
  return {
    type: NUMBER_SUCCESS,
    payload: numbersList
  };
}

/**
 * Defining the numbersFailure function, that gives back an error.
 */
export function numbersFailure(errorFound: boolean) {
  return {
    type: NUMBER_FAILURE,
    payload: errorFound
  };
}

/**
 * getNumbers is a function that fetches from the Express Server.
 */
async function getNumbers() {
  try {
    const response = await fetch(dashboardEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" }
    });

    const json = await response.text();
    return json;
  } 
  
  catch (error) {
    throw error;
  }
}

/**
 * loadNumbers is a function that gives back all the numbers and all
 * the information given about them.
 */
export function loadNumbers() {
  return async (dispatch: any) => {
    dispatch(numbersRequest(true));
    await getNumbers()
      .then((data: string) => {
        dispatch(numbersSuccess(JSON.parse(data)));
      })

      .catch((error: any) => {
        console.log(error);
        dispatch(numbersFailure(true));
      });
  };
}
