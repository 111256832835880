/**
 * Importing the modules from react and react-dom.
 */
import React from 'react';
import ReactDOM from 'react-dom';

/**
 * Other imports.
 */
import './index.css';
import App from './client/containers/MainComponent';
import * as serviceWorker from './serviceWorker';

/** Rendering the App component to the root div in the index.html */
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
