/**
 * Importing data's interface and action types declared in detailTypes.ts 
 */
import { 
  IDetail, 
  DETAIL_REQUEST, 
  DETAIL_SUCCESS, 
  DETAIL_FAILURE, 
  IDetailActionTypes 
} from './detailTypes';

/**
 * This is the data interface that will be utilzed by the actions defined
 * for the details.
 */
const initialState: IDetail = {
  data: {},
  loading: true,
  error: false
};

/**
 * Defining detailReducer, a function that switches over the actions that
 * are being dispatched to it.
 */
export function detailReducer(state = initialState, action: IDetailActionTypes): IDetail {
  switch (action.type) {
    case DETAIL_REQUEST: {
      return { 
        ...state, 
        data: {}, 
        loading: action.payload, 
        error: false 
      };
    }

    case DETAIL_SUCCESS: {
      return { 
        ...state, 
        data: action.payload, 
        loading: false, 
        error: false 
      };
    }

    case DETAIL_FAILURE: {
      return { 
        ...state, 
        data: {}, 
        loading: false, 
        error: true 
      };
    }

    default: {
      return state;
    }
  }
}