/**
 * Importing action types.
 */
import { DETAIL_REQUEST, DETAIL_SUCCESS, DETAIL_FAILURE } from "./detailTypes";

/**
 * Defining fetch endpoint.
 */
const calldetailEndpoint: string = "/dashboard/calldetail";

/**
 * Defining the detailRequest function that takes a boolean as param.
 */
export function detailRequest(newRequest: boolean) {
  return {
    type: DETAIL_REQUEST,
    payload: newRequest
  };
}

/**
 * Defining detailSuccess function that takes an object as param.
 */
export function detailSuccess(callDetails: object) {
  return {
    type: DETAIL_SUCCESS,
    payload: callDetails
  };
}

/**
 * Defining detailFailure function that takes an object as param.
 */
export function detailFailure(errorFound: boolean) {
  return {
    type: DETAIL_FAILURE,
    payload: errorFound
  };
}

/**
 * Defining getDetail, a function that fetches the endpoint indicated in the
 * beginning of this file.
 */
async function getDetail(payload: any) {
  try {
    const response = await fetch(calldetailEndpoint, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json" }
    });

    const json = await response.json();
    return json;
  }

  catch (error) {
    throw error;
  }
}

/**
 * Defining loadDetail function, the core function of this file.
 * With this function we can retrieve from the endpoint the call details.
 */
export function loadDetail(numbersList: any[], selectedDate?: Date) {
  return async (dispatch: any) => {
    dispatch(detailRequest(true));
    let detailList: any[] = [];

    for (let index = 0; index < numbersList.length; index++) {
      const payload = {
        numbersList: numbersList[index],
        selectedDate
      };

      await getDetail(payload)
        .then((data: object) => {
          detailList.push(data);

          let isEqual: boolean =
            detailList.length === numbersList.length ? true : false;

          if (isEqual) {
            dispatch(detailSuccess(detailList));
          }
        })

        .catch((error: any) => {
          console.log(error);
          dispatch(detailFailure(true));
        });
    }
  };
}
