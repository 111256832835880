/**
 * Importing modules from react, react-redux, react-router-dom.
 */
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';

/**
 * Importing components and importing the configuration function
 * for the redux store.
 * 
 * @Login
 * @Dashboard
 */
import Login from '../components/loginComponent';
import Dashboard from '../components/dashboardComponent';
import Detail from '../components/detailComponent';
import { configureStore } from '../store/store';

/**
 * @store
 */
const store = configureStore();

/**
 * Defining the App Component.
 * 
 * @App
 */
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div>
            <Route exact path="/" component={Login} />
          	<Route path="/dashboard" component={Dashboard} />
            <Route path="/detail" component={Detail} />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
