/**
 * Importing modules from react, react-redux and react-spinners.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashLoader } from 'react-spinners';
import posed from 'react-pose';
import _ from 'lodash';

/**
 * Importing actions, AppState.
 */
import { AppState } from '../store/store';

/**
 * Importing bootstrap, font-awesome and dashboardComponent.css.
 */
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../../node_modules/font-awesome/css/font-awesome.min.css';
import '../styles/detailComponent.css';

/**
 * Describing the AppProps interface.
 */
interface AppProps {
  numbers: any,
  history: any,
  location: any,
}

/**
 * Describing IState interface
 */
interface IState {
  isVisible: boolean,
  passCheck: number
}

const Box = posed.div({
	hidden: { opacity: 0 },
	visible: { opacity: 1 }
});

let groupedCalls: any = {};
let typesCount = 0;

/**
 * Describing Detail Component.
 */
class Detail extends Component<AppProps, IState> {
  settingVisibility: any = null;
  settingPassCheck: any = null;

  constructor(props: AppProps) {
    super(props);

    this.state = {
      isVisible: false,
      passCheck: 0
    }

    this.backToDashboard = this.backToDashboard.bind(this);
  }

  /**
   * When component did mount I will get the specific number from the 
   * renderNumbersComponent and I will send it to loadDetail function
   * as a param.
   */
  async componentDidMount() {
    if (this.props.location.state !== undefined && this.props.numbers.data.Response !== undefined) {
      this.settingVisibility = setTimeout(() => {
        this.setState({
          isVisible: !this.state.isVisible	
        });
      }, 500);
    }

    else {
      this.redirectUser();
    }
  }

  compareByType() {
    this.props.location.state.detail.Calldetails.sort(
      (a: any, b: any) => (a.Type > b.Type) ? 1 : ((a.Type < b.Type) ? -1 : 0) 
    );
  }

  componentDidUpdate() {
    if(this.props.location.state.detail !== undefined && this.state.passCheck === 0) {
      this.compareByType();

      groupedCalls = _.groupBy(this.props.location.state.detail.Calldetails, function(call: any) {
        return call.Type;
      });

      typesCount = Object.keys(groupedCalls).length;

      this.settingPassCheck = setTimeout(() => {
        this.setState({
          passCheck: 1
        });
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.settingVisibility);
  }

  redirectUser() {
    this.props.history.push('/');
    return;
  }

  backToDashboard() {
    this.props.history.push('/dashboard');
  }

  /**
   * Rendering out the table with all the calls registry detail.
   */
  render() {
    if (this.state.passCheck === 1) {
      if (true) {
        let testArr: any[] = [];

        for (let index = 0; index < typesCount; index++) {
          testArr.push(
            <thead className="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Numero</th>
                <th scope="col">Portato</th>
                <th scope="col">Data</th>
                <th scope="col">Descrizione</th>
                <th scope="col">Durata</th>
                <th scope="col">Consumo</th>
                <th scope="col">Type</th>
              </tr>
            </thead>
          );
        }

        let propertiesList: any[] = Object.keys(groupedCalls);
        let propertiesIndex: number = 0;
        let consumptionsDividedByCategory: any[] = [];
        let durationInSecondsDividedByCategory: any[] = [];
        let durationInMinutesDividedByCategory: any[] = [];

        for (let index = 0; index < propertiesList.length; index++) {
          if (groupedCalls[propertiesList[index]] === 'non geografiche') {
            consumptionsDividedByCategory.push(0);
            durationInSecondsDividedByCategory[index] = 
              groupedCalls[propertiesList[index]].reduce((sum: any, element: any) => sum + parseInt(element.Duration, 10), 0);
            durationInMinutesDividedByCategory[index] = 
              Math.round(groupedCalls[propertiesList[index]].reduce((sum: any, element: any) => sum + parseInt(element.Duration, 10), 0) / 60);
            continue;
          }

          else {
            consumptionsDividedByCategory[index] = 
              groupedCalls[propertiesList[index]].reduce((sum: any, element: any) => sum + parseFloat(element.Price), 0);

            durationInSecondsDividedByCategory[index] = 
              groupedCalls[propertiesList[index]].reduce((sum: any, element: any) => sum + parseInt(element.Duration, 10), 0);

            durationInMinutesDividedByCategory[index] = 
              Math.round(groupedCalls[propertiesList[index]].reduce((sum: any, element: any) => sum + parseInt(element.Duration, 10), 0) / 60);
          }
        }

        return (
          <div className="container detailComponentMain bg-dark p-5">
            <div className="row">
              <div>
                <h4 className="">Dettaglio di {this.props.location.state.ragioneSociale}</h4>
              </div>

              <div>
                <button className="btn btn-success ml-3" onClick={this.backToDashboard}>
                  <i className="fa fa-arrow-left"></i>
                </button>
              </div>

              <div>
                <button className="btn btn-success ml-2" onClick={() => window.print()}>
                  <i className="fa fa-print"></i>
                </button>
              </div>
            </div>

            <div className="alert alert-danger row mr-3 mt-3" role="alert">
              Attenzione: Prima dei prossimi aggiornamenti, non sara' possibile effettuare molteplici refresh di pagina.
            </div>

            <div className="row table-responsive mt-3 rounded">
              <table className="table table-striped table-dark">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Numero</th>
                    <th scope="col">Portato</th>
                    <th scope="col">Data Attivazione</th>
                    <th scope="col">Ch. Contemporanee</th>
                    <th scope="col">Rag. Sociale</th>
                    <th scope="col">Consumo</th>
                  </tr>
                </thead>
        
                <tbody>
                  <tr key={this.props.location.state.number}>
                    <th scope="row">1</th>
                    <td>{this.props.location.state.number}</td>
                    <td>{this.props.numbers.data.Response[0].NumberList[this.props.location.state.index].Portato}</td>
                    <td>{this.props.numbers.data.Response[0].NumberList[this.props.location.state.index].DataAttivazione}</td>
                    <td>{this.props.numbers.data.Response[0].NumberList[this.props.location.state.index]['Chiamate contemporanee']}</td>   
                    <td>{this.props.numbers.data.Response[0].NumberList[this.props.location.state.index].RagioneSociale}</td>
                    <td>{this.props.location.state.total}&euro;</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="alert alert-success row mr-3" role="alert">
              Nota: Al di sotto di questo alert, sono presenti i totali suddivisi per tipologia di chiamata.
            </div>

            <div className="row table-responsive rounded">
              <table className="table table-striped table-dark">
                <thead className="thead-light">
                  <tr>
                    {
                      propertiesList.map((element: any, index: number) => {
                        return (
                          <th scope="col" key={index}>{element[0].toUpperCase() + element.slice(1)}</th>
                        );
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {
                      consumptionsDividedByCategory.map((element: any, index: number) => {
                        return (
                          <td key={index}>Consumo: {element.toFixed(2)}&euro;</td>
                        );
                      })
                    }
                  </tr>
                  <tr>
                    {
                      consumptionsDividedByCategory.map((element: any, index: number) => {
                        return (
                          <td key={index}>Chiamate: {Object.keys(groupedCalls[propertiesList[index]]).length}</td>
                        );
                      })
                    }
                  </tr>
                  <tr>
                    {
                      durationInMinutesDividedByCategory.map((element: any, index: number) => {
                        return (
                          <td key={index}>Durata min.: {element}</td>
                        );
                      })
                    }
                  </tr>
                  <tr>
                    {
                      durationInSecondsDividedByCategory.map((element: any, index: number) => {
                        return (
                          <td key={index}>Durata sec.: {element}</td>
                        );
                      })
                    }
                  </tr>
                  <tr>
                    {
                      consumptionsDividedByCategory.map((element: any, index: number) => {
                        return (
                          <td key={index}>Costo/Secondo: {((element/durationInSecondsDividedByCategory[index])*3).toFixed(3)}&euro;</td>
                        );
                      })
                    }
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="row table-responsive mt-3 rounded">
              <Box pose={this.state.isVisible ? 'visible' : 'hidden'} >
                {testArr.map((e: any, i: number) => {
                  return (
                    <div key={i}>
                      <table className="table table-striped table-dark pb-3">
                        {e}
                        <tbody>
                        {
                          groupedCalls[propertiesList[propertiesIndex]].map((e: any, indexKey: number) => {
                            return (
                              <tr key={indexKey}>
                                <th scope="row">{indexKey+1}</th>
                                <td>{e.Called}</td>
                                <td>{e.Calling}</td>
                                <td>{e.DateTime}</td>
                                <td>{e.Description}</td>   
                                <td>{e.Duration}</td>
                                <td>{e.Price}</td>
                                <td>{e.Type}</td>
                              </tr>
                            );
                          },  
                          propertiesIndex++
                        )}
                        </tbody>
                      </table>
                    </div>
                  );
                })}
              </Box>
            </div>
          </div>
        );
      }

      else {
        return (
          <div className="detailComponentLoadingError">
            An error has occurred, you will be redirected to the login page.
            {this.redirectUser()}
          </div>
        );
      }
    }

    else {
      return (
				<div className="detailComponentLoadingError">
					<HashLoader color={'#28a745'} size={75} />
				</div>
      );
    }
  }
}

/**
 * Mapping redux store state to component props.
 */
const mapStateToProps = (state: AppState) => {
  return {
    numbers: state.numbers,
  };
};


/**
 * Exporting detail and connecting it to the redux store.
 */
export default connect(
  mapStateToProps,
  {}
)(Detail);